<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">司机车辆配置</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
      >配置
      </a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="车辆负责人">
              <a-tree-select
                allowClear
                :treeData="userList"
                v-model="chargeUserStr"
                @change="beforeSearch"
                placeholder="请选择车辆负责人"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="司机">
              <a-tree-select
                allowClear
                :treeData="userList"
                v-model="driverStr"
                @change="beforeSearch"
                placeholder="请选择司机"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="车辆名称">
              <a-select
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchParams.carInfo"
                placeholder="请选择"
                @change="search"
              >
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="ifDefault" slot-scope="text, record">
          <a-tag v-if="record.ifDefault === true" color="#87d068">是</a-tag>
          <a-tag v-if="record.ifDefault === false" >否</a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="司机车辆配置" v-model="addModal" width="560px">
      <car-config-form ref="addRef" @addSuccess="addSuccess" type="add" @addError="addError"></car-config-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改配置"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <car-config-form ref="editRef"  type="edit" @editSuccess="editSuccess" @editError="editError"></car-config-form>
    </a-modal>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { carConfigColumns } from './common/common'
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import CarConfigForm from './Form'

  export default {
    name: 'carInfoList',
    components: { CarConfigForm, PageLayout },
    mixins: [entityCRUDWithCopy],
    data() {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carConfigApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          chargeUser: undefined,
          driver: undefined,
          carInfo: undefined
        },
        initColumns: carConfigColumns(),
        carList: [],
        userList: [], //负责人、司机下拉数据，
        chargeUserStr: undefined,
        driverStr: undefined,
      }
    },
    created() {
      this.loadUserList()
      this.loadCarInfoList()
    },
    methods: {
      loadUserList() {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          },
        })
      },
      loadCarInfoList() {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          },
        })
      },
      beforeSearch() {
        this.searchParams.chargeUser = this.chargeUserStr ? this.chargeUserStr.split('_')[2] : undefined
        this.searchParams.driver = this.driverStr ? this.driverStr.split('_')[2] : undefined
        this.search()
      }
    }
  }
</script>

<style scoped></style>
